body {
  background-color: #f2f1f1;
}

.pedidos {
  height: 256px;
  width: 360px;
}
.pedidos {
  cursor: pointer;
  border-radius: 10px;
  height: 256px;
  width: 360px;
  background-color: #ffffff;
}
.text-1 {
  text-align: center;
  vertical-align: middle;
  font-size: 24px;
  font-family: Open Sans;
  line-height: auto;
  color: #707070;
}
.img-2 {
  border-radius: 10px;
  height: 120px;
  width: 120px;
}

.myButton {
  color: #2a3176;
  background-color: #f4f4f4;
  font-weight: bold;
  font-family: Open Sans;
}

/* DASBOARD */

.rootGrid {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.myGrid {
  /* padding: 10px; */
  /* margin-right: 10px; */
  min-width: 320px;
  max-width: 320px;
  min-height: 170px;
  background-color: #f4f4f4;
  /* // margin: theme.spacing(1); */
  align-items: center;
  justify-content: center;
  display: block;
  text-align: center;
  cursor: pointer;
  border: 2px solid #c5c5c5;
  border-radius: 10px;
  /* // direction: 'column'; */
}
.myGridS {
  min-width: 320px;
  max-width: 320px;
  min-height: 170px;
  background-color: #2a3176;
  /* margin: theme.spacing(1); */
  margin: 10px;
  align-items: center;
  justify-content: center;
  display: block;
  text-align: center;
  cursor: pointer;
  border: 2px solid #c5c5c5;
  border-radius: 10px;
  /* // direction: 'column'; */
}
.myLabel {
  padding: 10px;
  font-family: Open Sans;
  color: #707070;
  font-weight: bold;
}

.myLabelS {
  padding: 10px;
  font-family: Open Sans;
  color: #fff;
  font-weight: bold;
}

/* NEW PRODUCT */

.pmyGrid {
  min-width: 320px;
  max-width: 320px;
  min-height: 170px;
  background-color: #f4f4f4;
  /* margin: theme.spacing(1); */
  /* margin: 10px; */
  align-items: center;
  justify-content: center;
  display: flex;
  text-align: center;
  cursor: pointer;
  border: 2px solid #c5c5c5;
  border-radius: 10px;
  /* // direction: 'column'; */
}
.pmyLabel {
  /* padding: 10px; */
  font-family: Open Sans;
  color: #2a3176;
  font-weight: bold;
}

/* TYPE PRODUCT */

.typePMyGridS {
  min-width: 300px;
  max-width: 300px;
  min-height: 100px;
  background-color: #2a3176;
  /* margin: theme.spacing(1); */
  margin: 10px;
  align-items: center;
  justify-content: center;
  display: block;
  text-align: center;
  cursor: pointer;
  border: 2px solid #c5c5c5;
  /* color: #fff; */
  border-radius: 10px;
  /* // direction: 'column'; */
}

.typepmylabel {
  padding: 10px;
  font-family: Open Sans;
  color: #fff;
  font-weight: bold;
}

/* SELECT OPTIONS */
.mytextfield {
  background-color: none;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  border: none;
  color: #717171;
  font-weight: bold;
  font-family: Open Sans;
}

textarea:focus,
input:focus,
select:focus {
  box-shadow: 0 0 0 0;
  border: 0 none;
  outline: 0;
}

.mytext {
  font-family: Open Sans;
  font-weight: bold;
  color: #717171;
}
