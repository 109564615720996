/* .login {
  border-radius: 12px;
  height: 80px;
  width: 356px;
  background-color: #41b490;
  text-align: left;
  vertical-align: top;
  font-size: 36px;
  font-family: Open Sans;
  line-height: auto;
  color: #ffffff;
}

.rectangle-7 {
  color: #ffffff;
  border-radius: 12px;
  height: 80px;
  width: 356px;
  background-color: #41b490;
} */
.continuar {
  height: 60px;
  width: 250px;
}

.rectangle-7 {
  cursor: pointer;
  border-radius: 10px;
  height: 50px;
  width: 150px;
  border: 1px solid #fff;
  /* font-size: 18px; */
  font-family: Open Sans;
  font-weight: bold;
  color: #ffffff;
  background-color: #41b490;
}

/* .rectangle-7:hover {
  pointer-events: none;
} */
.text-1 {
  text-align: center;
  vertical-align: middle;
  /* font-size: 24px; */
  font-family: Open Sans;
  line-height: auto;
  color: #ffffff;
}
