.base {
  display: inline-flex;
  flex-direction: row;
}

.lateral {
  width: 40vw;
  height: 100vh;
  background-color: #2a3176;
  text-align: center;
}

.baseLogin {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  height: 100vh;
  width: 60vw;
}

.baseAvatar {
  background-color: #ffffff;
}

.login1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.textPosition {
  margin: 80px;
  padding: 20px;
  text-align: left;
}

.textWelcome {
  vertical-align: top;
  font-family: Open Sans;
  font-weight: bold;
  line-height: auto;
  color: #ffffff;
}

.textLogin {
  text-align: center;
  vertical-align: top;
  font-family: Open Sans;
  line-height: auto;
  color: #41b490;
  font-weight: bold;
}

.textForgot {
  text-align: center;
  vertical-align: top;
  font-family: Open Sans;
  line-height: auto;
  color: #005d9f;
  font-weight: bold;
}

.logo {
  margin-top: 80px;
  width: 426px;
}
