.newProductTitle {
  text-align: left;
  vertical-align: middle;
  /* font-size: 30px; */
  font-family: Open Sans;
  line-height: auto;
  color: #707070;
}

.btn-new-products {
  border-radius: 10px;
  height: 233px;
  width: 386px;
  background-color: #f4f4f4;
  cursor: pointer;
  border: 1px solid #eee;
  box-shadow: 1px 4px #bebaba;
  text-align: center;
  vertical-align: middle;
  font-size: 36px;
  font-family: Open Sans;
  line-height: auto;
  color: #2a3176;
}

.btn-new-products:active {
  background-color: #41b490;
  color: #ffffff;
}

.btn-new-products-text {
  text-align: center;
  vertical-align: middle;
  font-size: 36px;
  font-family: Open Sans;
  line-height: auto;
  color: #2a3176;
}

.text-new-products-all {
  text-align: left;
  vertical-align: top;
  /* font-size: 32px; */
  font-family: Open Sans;
  line-height: auto;
  color: #41b490;
  cursor: pointer;
}
