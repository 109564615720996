.modalbranco {
  height: 776px;
  /* width: 1000px; */
}
.text-1 {
  text-align: right;
  padding-right: auto;
  vertical-align: middle;
  /* font-size: 36px; */
  font-family: Open Sans;
  line-height: auto;
  color: #2a3176;
}
.codigoenviado {
  align-items: center;
  justify-content: center;
  align-content: center;
  height: 300px;
  width: 1192px;
}
.img-2 {
  text-align: center;
  align-items: center;
  justify-content: center;
  height: 220px;
  width: 220px;
}
.text-3 {
  text-align: center;
  vertical-align: middle;
  /* font-size: 36px; */
  font-family: Open Sans;
  line-height: auto;
  color: #41b490;
}
.o-k {
  height: 60px;
  width: 238px;
}
.o-k {
  border-radius: 20px;
  height: 60px;
  width: 238px;
  background-color: #41b490;
}
.text-4 {
  text-align: center;
  vertical-align: middle;
  /* font-size: 36px; */
  font-family: Open Sans;
  line-height: auto;
  color: #ffffff;
}
