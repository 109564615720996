/* .tarjabranca {
  height: 60px;
  width: 1220px;
} */
.tarjabranca {
  border-radius: 10px;
  height: 60px;
  width: 1220px;
  background-color: #ffffff;
}
.txt-notification {
  /* font-weight: 500; */
  text-align: left;
  vertical-align: middle;
  /* font-size: 24px; */
  font-family: Open Sans;
  line-height: auto;
  color: #e22d2d;
}
.txt-notification-description {
  /* text-align: left; */
  /* font-weight: 500; */
  vertical-align: middle;
  /* font-size: 24px; */
  font-family: Open Sans;
  line-height: auto;
  color: #707070;
}

.txt-notification-intern-title {
  /* text-align: center; */
  font-weight: bold;
  vertical-align: middle;
  /* font-size: 32px; */
  font-family: Open Sans;
  line-height: auto;
  color: #e22d2d;
}

.txt-notification-intern-description {
  text-align: left;
  vertical-align: middle;
  /* font-size: 24px; */
  font-family: Open Sans;
  line-height: auto;
  color: #3c3c3b;
}
