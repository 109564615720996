.container {
  display: flex;
}

.content {
  margin-top: 60px;
  flex-grow: 1;
  background: #f4f5f7;
  padding: 2em;
}

.textCheckoutInform {
  text-align: left;
  vertical-align: top;
  /* font-size: 36px; */
  font-weight: 600;
  font-family: Open Sans;
  line-height: auto;
  color: #2a3176;
}

.textTitle {
  margin-left: 50px;
  font-weight: bold;
  text-align: left;
  vertical-align: middle;
  /* font-size: 36px; */
  font-family: Open Sans;
  line-height: auto;
  color: #2a3176;
}

.textMenu {
  /* text-align: center; */
  margin-left: 50px;
  vertical-align: middle;
  /* font-size: 24px; */
  font-family: Open Sans;
  line-height: auto;
  color: #3b449e;
  cursor: pointer;
}

.textMailLabels {
  /* text-align: center; */
  vertical-align: middle;
  /* font-size: 24px; */
  font-family: Open Sans;
  line-height: auto;
  color: #3c3c3b;
}

.labelattachment {
  color: #41b490;
  font-family: Open Sans;
  /* font-size: 20px; */
  font-weight: bold;
}

.textCheckoutInform {
  text-align: left;
  vertical-align: top;
  /* font-size: 36px; */
  font-weight: 600;
  font-family: Open Sans;
  line-height: auto;
  color: #2a3176;
}

.textExit {
  cursor: pointer;
  text-align: center;
  vertical-align: middle;
  font-family: Open Sans;
  line-height: auto;
  color: #657588;
}

.textBoxInform {
  text-align: left;
  vertical-align: top;
  margin-top: 20px;
  font-family: Open Sans;
  line-height: auto;
  color: #41b490;
}

.textBoxInform2 {
  text-align: left;
  vertical-align: top;
  font-family: Open Sans;
  line-height: auto;
  color: rgba(112, 112, 112, 0.6499999761581421);
}

.textNfInform {
  text-align: center;
  font-weight: bold;
  vertical-align: top;
  margin-top: 20px;
  font-family: Open Sans;
  line-height: auto;
  color: #41b490;
}

.descriptionTable {
  text-align: left;
  vertical-align: middle;
  /* font-size: 26px; */
  font-family: Open Sans;
  line-height: auto;
  color: #2a3176;
  font-weight: bold;
}

.labelGridSecond {
  text-align: left;
  vertical-align: middle;
  font-family: Open Sans;
  line-height: auto;
  margin-top: 20px;
  color: #848484;
}

.labelTitleResum {
  font-weight: bold;
  vertical-align: middle;
  font-family: Open Sans;
  line-height: auto;
  color: #717171;
  margin-right: 5px;
}

.textSuccess {
  font-family: Open Sans;
  line-height: auto;
  color: #41b490;
}
