.text-checkout-inform {
  text-align: left;
  vertical-align: top;
  /* font-size: 36px; */
  font-weight: 600;
  font-family: Open Sans;
  line-height: auto;
  color: #2a3176;
}

.box-new-card {
  display: grid;
  padding-right: 20px;
  padding-left: 20px;
  /* margin: 20px; */
  /* justify-content: center;
  align-items: center; */
  border: 1px solid #c5c5c5;
  border-radius: 10px;
}

.text-box-inform {
  text-align: left;
  vertical-align: top;

  /* font-size: 22px; */
  font-family: Open Sans;
  line-height: auto;
  color: #41b490;
}

.text-box-inform-2 {
  text-align: left;
  vertical-align: top;
  font-size: 20px;
  font-family: Open Sans;
  line-height: auto;
  color: rgba(112, 112, 112, 0.6499999761581421);
}

.text-select-label {
  text-align: left;
  vertical-align: middle;
  /* font-size: 18px; */
  font-family: Open Sans;
  line-height: auto;
  color: #848484;
  margin-bottom: 1px;
}

.myInput {
  border-radius: 10px;
  border: 1px solid;
  height: 50px;
  width: 275px;
  background-color: #ffffff;
  text-align: left;
  padding-left: 20px;
  vertical-align: middle;
  font-size: 14px;
  font-family: Open Sans;
  line-height: auto;
  color: #9c9c9c;
}

.myInput-tax {
  /* margin: 0 10px; */
  border-radius: 10px;
  border: 1px solid;
  height: 50px;
  width: 240px;
  background-color: #ffffff;
  text-align: left;
  padding-left: 20px;
  vertical-align: middle;
  font-size: 14px;
  font-family: Open Sans;
  line-height: auto;
  color: #9c9c9c;
}

.myInput-tax-owner {
  /* margin: 0 10px; */
  border-radius: 10px;
  border: 1px solid;
  height: 50px;
  width: 200px;
  background-color: #ffffff;
  text-align: left;
  padding-left: 20px;
  vertical-align: middle;
  font-size: 14px;
  font-family: Open Sans;
  line-height: auto;
  color: #9c9c9c;
}

.myInput-tax-owner2 {
  /* margin: 0 10px; */
  margin-left: 25px;
  border-radius: 10px;
  border: 1px solid;
  height: 50px;
  width: 200px;
  background-color: #ffffff;
  text-align: left;
  padding-left: 20px;
  vertical-align: middle;
  font-size: 14px;
  font-family: Open Sans;
  line-height: auto;
  color: #9c9c9c;
}

.myLabel {
  font-size: 14px;
  font-family: Open Sans;
  line-height: auto;
  color: #9c9c9c;
}

.myCheckbox {
  border-radius: 5px;
  height: 25px;
  width: 25px;
  background-color: #ffffff;
  border: 0px solid #3c3c3b;
}

.myFormGroup {
  margin-left: 1px;
}

.text-exit {
  text-align: center;
  vertical-align: middle;
  font-family: Open Sans;
  line-height: auto;
  color: #657588;
}

.review-label {
  font-weight: 500;
  text-align: left;
  vertical-align: middle;
  font-size: 16px;
  font-family: Open Sans;
  line-height: auto;
  color: #707070;
}

.review-value {
  margin-left: 5px;
  text-align: left;
  vertical-align: middle;
  font-size: 14px;
  font-family: Open Sans;
  line-height: auto;
  color: #848484;
}

.review-img {
  margin-left: 10px;
}

.review-label-1 {
  text-align: left;
  vertical-align: middle;
  font-size: 18px;
  font-weight: bold;
  font-family: Open Sans;
  line-height: auto;
  color: #848484;
}

.btn-category {
  border-radius: 10px;
  /* height: 95px;
  width: 290px; */
  background-color: #f4f4f4;
  border: 1px solid #eee;
  box-shadow: 1px 4px #bebaba;
  cursor: pointer;
  text-align: center;
  vertical-align: middle;
  /* font-size: 24px; */
  font-family: Open Sans;
  line-height: auto;
  color: #2a3176;
}

/* .btn-category-selected {
  border-radius: 10px;
  height: 95px;
  width: 290px;
  background-color: #41b490;
  border: 1px solid #eee;
  box-shadow: 1px 4px #bebaba;
  cursor: pointer;
  text-align: center;
  vertical-align: middle;
  font-size: 24px;
  font-family: Open Sans;
  line-height: auto;
  color: #ffffff;
} */

.label-spec {
  text-align: left;
  vertical-align: middle;
  /* font-size: 30px; */
  font-family: Open Sans;
  line-height: auto;
  color: #657588;
  font-weight: bold;
  cursor: pointer;
}

.label-spec-selected {
  text-align: left;
  vertical-align: middle;
  /* font-size: 30px; */
  font-family: Open Sans;
  line-height: auto;
  color: #41b490;
  font-weight: bold;
  cursor: pointer;
}

.label-card-exist {
  text-align: left;
  vertical-align: middle;
  /* font-size: 24px; */
  font-family: Open Sans;
  line-height: auto;
  color: #848484;
}
