.textMenu {
  /* text-align: center; */
  margin-left: 50px;
  vertical-align: middle;
  /* font-size: 24px; */
  font-family: Open Sans;
  line-height: auto;
  color: #3b449e;
  cursor: pointer;
}

.textTitle {
  margin-left: 50px;
  font-weight: bold;
  text-align: left;
  vertical-align: middle;
  /* font-size: 36px; */
  font-family: Open Sans;
  line-height: auto;
  color: #2a3176;
}

.modal-details-order-box {
  /* display: flex; */
  padding-left: 20px;
  flex-direction: row;
  /* justify-content: space-between; */
  /* min-width: 1220px;
  min-height: 200px; */
  border-radius: 10px;
  border: 1px solid #707070;
}

.modal-details-order-box-label-header {
  text-align: left;
  vertical-align: middle;
  /* font-size: 22px; */
  font-weight: bold;
  font-family: Open Sans;
  line-height: auto;
  color: #2a3176;
}

.modal-detail-box-body {
  display: flex;
  flex-direction: column;
  /* max-width: 216px; */
}

.label-planilhas {
  color: #41b490;
  font-family: Open Sans;
  /* font-size: 20px; */
  font-weight: bold;
  margin-left: 15px;
}

.label-attachment {
  color: #41b490;
  font-family: Open Sans;
  /* font-size: 20px; */
  font-weight: bold;
}

.img-planilhas {
  height: 20px;
  width: 20px;
  margin-right: 15px;
  cursor: pointer;
}

.label-descart {
  text-align: left;
  vertical-align: middle;
  /* font-size: 24px; */
  font-family: Open Sans;
  line-height: auto;
  font-weight: bold;
  color: #657588;
}

.label-addItem {
  text-align: left;
  vertical-align: middle;
  /* font-size: 24px; */
  font-family: Open Sans;
  line-height: auto;
  font-weight: bold;
  color: #41b490;
  margin-right: 15px;
}

.label-goback {
  text-align: left;
  vertical-align: top;
  /* font-size: 24px; */
  font-family: Open Sans;
  line-height: auto;
  color: #657588;
  font-weight: bold;
  margin-right: 20px;
}
