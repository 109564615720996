.myStyle {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  height: 83px;
  max-width: 592px;
  background-color: #f4f4f4;
  text-align: center;
  vertical-align: top;
  font-size: 36px;
  font-family: Open Sans;
  line-height: auto;
  color: #707070;
  margin: 30px;
}

.inputStyle {
  border-radius: 20px;
  /* height: 83px;
  width: 592px; */
  background-color: #f4f4f4;
  text-align: center;
  vertical-align: top;
  font-size: 26px;
  font-family: Open Sans;
  line-height: auto;
  border: none;
  color: #707070;
}

.inputStyle:focus {
  box-shadow: 0 0 0 0;
  border: 0 none;
  outline: 0;
}
