.menufont {
  font-family: open Sans;
  font-weight: semibold;
  color: #717171;
  font-size: 24px;
}

.menufontS {
  font-family: open Sans;
  font-weight: semibold;
  color: #2a3176;
  font-size: 24px;
}
