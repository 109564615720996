.busca {
  border-radius: 10px;
  height: 40px;
  min-width: 300px;
  background-color: #ffffff;
  text-align: left;
  vertical-align: middle;
  /* font-size: 24px; */
  font-family: Open Sans;
  line-height: auto;
  color: #707070;
}

.headertable {
  background-color: #005d9f;
  /* text-align: left;
  vertical-align: middle; */
  /* font-size: 22px; */
  font-family: Open Sans;
  color: #ffffff;
}

#refresh {
  height: 25px;
  width: 25px;
}

#refresh.animar {
  animation: is-rotating 1s 2;
}

@keyframes is-rotating {
  to {
    transform: rotate(1turn);
  }
}

.modal-header {
  text-align: left;
  vertical-align: top;
  font-size: 36px;
  font-family: Open Sans;
  line-height: auto;
  font-weight: bold;
  color: #2a3176;
}

.modal-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 1072px;
  min-height: 341px;
  border-radius: 10px;
  border: 1px solid #707070;
}

.modal-box-body {
  display: flex;
  flex-direction: column;
  max-width: 216px;
  /* border-radius: 10px;
  border: 1px solid #707070; */
}

.modal-box-body-header-label {
  text-align: left;
  /* margin-bottom: 30; */
  vertical-align: middle;
  font-size: 18px;
  font-family: Open Sans;
  line-height: auto;
  font-weight: bold;
  color: #707070;
}

.modal-box-body-value-label {
  margin-top: -20px;
  text-align: left;
  vertical-align: middle;
  font-size: 16px;
  font-family: Open Sans;
  line-height: auto;
  color: #707070;
}

.modal-notification {
  margin-top: 20px;
  width: 1072px;
  height: 90px;
  border-radius: 10px;
  border: 1px solid #707070;
}
