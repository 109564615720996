.drawer {
  width: 160px;
  flex-shrink: 0;
}

.drawerPaper {
  width: 160px;
  background: white;
  border-right: 3px solid #eff1f3;
}

.drawerContainer {
  overflow: auto;
  padding: 1em;
}

.link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #717171;
  text-align: center;
  margin-top: 1.2em;
  cursor: pointer;
  text-decoration: none;
}
