.new-category {
  /* margin-left: 30px; */
  text-align: left;
  vertical-align: top;
  /* font-size: 35px; */
  font-family: Open Sans;
  line-height: auto;
  color: #2a3176;
  font-weight: bold;
}

.x {
  margin-right: 30px;
  text-align: left;
  vertical-align: middle;
  /* font-size: 40px; */
  font-family: Open Sans;
  line-height: auto;
  color: #2a3176;
  cursor: pointer;
  font-weight: 600;
}

.input-category {
  border: 1px solid #c5c5c5;
  border-radius: 10px;
  height: 60px;
  width: 952px;
  background-color: #ffffff;
  padding-left: 30px;
  vertical-align: middle;
  font-size: 24px;
  font-weight: 600;
  font-family: Open Sans;
  line-height: auto;
  color: #848484;
}

.box-category {
  /* height: 60px;
  width: 952px; */
  display: grid;
  padding: 30px;
  margin: 20px;
  justify-content: center;
  align-items: center;
  border: 1px solid #c5c5c5;
  border-radius: 10px;
}

.txt-category-back {
  cursor: pointer;
  /* margin: 20px; */
  text-align: left;
  vertical-align: top;
  /* font-size: 22px; */
  font-family: Open Sans;
  line-height: auto;
  font-weight: 600;
  color: #657588;
}

.txt-category-success {
  text-align: center;
  vertical-align: middle;
  /* font-size: 36px; */
  font-family: Open Sans;
  line-height: auto;
  color: #41b490;
}

.img-category-check {
  width: 120px;
}
