.longWidget {
  background-color: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 350px;
  padding: 1em;
}

.smallWidget {
  background-color: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 134px;
  padding: 1em;
}

.smallWidgetOk {
  background-color: rgb(0, 181, 141);
  color: rgb(255, 255, 255);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 134px;
  padding: 1em;
  cursor: pointer;
}

.smallWidgetError {
  background-color: rgb(181, 27, 0);
  color: rgb(255, 255, 255);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 134px;
  padding: 1em;
  cursor: pointer;
}

.smallWidgetAlert {
  background-color: rgb(234, 210, 73);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 134px;
  padding: 1em;
}

.smallWidgetTwoAlert {
  background-color: rgb(212, 119, 102);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 134px;
  padding: 1em;
  color: #fff;
}
